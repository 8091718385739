export default {
  tableFields: [
    {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },
    {
      name: 'detail.name',
      sortField: 'name',
      width: '20%',
      title:'Nombre'
    },
    {
      name: 'detail.code',
      sortField: 'code',
      width: '11%',
      title:'Ktag'
    },
    {
      name: 'detail.email',
      sortField: 'email',
      width: '20%',
      title:'Email'
    },


   /* {
      name:       '__slot:active',
      title:      'ACTIONS',
      titleClass: "center aligned",
      dataClass:  "center aligned",
      width:      "20%",
    } */
  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
    'location': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    }
  }
}
